import {z} from 'zod';

/**
 * FollowCompanyRanking
 */

const followCompanyRankingSchema = z.object({
  start: z.string(),
  end: z.string(),
  ranking: z.array(
    z.object({
      companyID: z.number(),
      companyName: z.string(),
      rank: z.number(),
      diff: z.number(),
      total: z.number(),
    }),
  ),
});

export const GetFollowCompanyRankingSchema = z.object({
  graduationYear: z.number(),
  daily: followCompanyRankingSchema,
  weekly: followCompanyRankingSchema,
  monthly: followCompanyRankingSchema,
});

export const TopCompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  logo_url: z.string(),
  background_image_url: z.string(),
  catch_phrase: z.string(),
});

export const TopCompanyListSchema = z.object({
  companies: z.array(TopCompanySchema),
});

/**
 * CompanyList
 */
export const CompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  industryCategoryID: z.number(),
  industry: z.string(),
  foundedYear: z.number(),
  employeeCount: z.number(),
  followerCount: z.number(),
  isFollowed: z.boolean(),
  isNew: z.boolean(),
  catchPhrase: z.string(),
  capitalDescription: z.string(),
  pageType: z.string(),
  backgroundImageURLs: z.object({
    '300x126': z.string(),
    '640x268': z.string(),
    '948x392': z.string(),
  }),
  logoURLs: z.object({
    '140x105': z.string(),
    '200x150': z.string(),
    '400x300': z.string(),
  }),
  isEngineerCompany: z.boolean(),
});

export const CompanyListSchema = z.object({
  companies: z.object({
    large: z.array(CompanySchema),
    medium: z.array(CompanySchema),
    small: z.array(CompanySchema),
    tiny: z.array(CompanySchema),
    archive: z.array(CompanySchema),
  }),
  realGraduationYear: z.number(),
  companyPageGraduationYear: z.number(),
  nextURLParam: z.object({
    cacheID: z.string(),
    page: z.number(),
    limit: z.number(),
    industryCategoryIDs: z.array(z.number()),
    q: z.string(),
  }),
});

const IndustryCategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  shortName: z.string(),
});

export const GetIndustryCategoryMasterSchema = z.object({
  industryCategories: z.array(IndustryCategorySchema),
});

const FollowCompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  isFollowed: z.boolean(),
});

const GroupedFollowCompanySchema = z.object({
  industryCategoryID: z.number(),
  industryCategoryName: z.string(),
  companies: z.array(FollowCompanySchema),
});

export const GetGroupedSupportCompanyMasterSchema = z.object({
  companyGroups: z.array(GroupedFollowCompanySchema),
});

export const companyInfoSchema = z.object({
  id: z.number(),
  name: z.string(),
  industryID: z.number().optional(), // ES・体験記詳細リリース時にoptionalを外す
  industryName: z.string(),
  industryCategoryID: z.number(),
  industryCategoryName: z.string(),
  followerCount: z.number(),
  followed: z.boolean(),
  logoURL: z.string(),
  mainPhotoURL: z.string(),
  isRecruiting: z.boolean(),
});

// POST /3.0/companies/{companyId}/follow
// DELETE /3.0/companies/{companyId}/follow
export const FollowCompanyCountSchema = z.object({
  followCompanyCount: z.number(),
});

// GET /3.0/companies/{companiId}/entries
export const EntrySchema = z.object({
  id: z.number(),
  title: z.string(),
  deadline: z.string(),
  targetGraduationYear: z.number().nullable(),
  status: z.string(),
  entryUrl: z.string(),
});

export const CompanyEntriesSchema = z.object({
  id: z.number(),
  name: z.string(),
  entries: z.array(EntrySchema),
});

// GET /3.0/companies/{companiId}/orientations
export const ScheduleSchema = z.object({
  id: z.number(),
  name: z.string(),
  venue: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  deadline: z.string(),
  capacity: z.number(),
  status: z.string(),
  entryUrl: z.string(),
});

export const OrientationSchema = z.object({
  id: z.number(),
  title: z.string(),
  deadline: z.string(),
  targetGraduationYear: z.number().nullable(),
  schedules: z.array(ScheduleSchema),
});

export const CompanyOrientationsSchema = z.object({
  id: z.number(),
  name: z.string(),
  orientations: z.array(OrientationSchema),
});

/**
 * EngineerCompanyList
 */
export const EngineerCompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  backgroundImageUrls: z.object({
    '300x126': z.string(),
    '640x268': z.string(),
    '948x392': z.string(),
  }),
  logoUrls: z.object({
    '140x105': z.string(),
    '200x150': z.string(),
    '400x300': z.string(),
  }),
  catchPhrase: z.string(),
  industryName: z.string(),
  followCount: z.number(),
  followed: z.boolean(),
  isArchive: z.boolean(),
});

export const EngineerCompanyListSchema = z.object({
  totalCount: z.number(),
  nextPage: z.number(),
  companies: z.array(EngineerCompanySchema),
});

const IndustryMasterSchema = z.object({
  id: z.number(),
  name: z.string(),
  shortName: z.string(),
  groupName: z.string(),
});

export const GetIndustryMasterSchema = z.object({
  industries: z.array(IndustryMasterSchema),
  q: z.string(),
});

export const GroupedCompanySchema = z.object({
  industryID: z.number(),
  industryName: z.string(),
  companyList: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ),
});

export const GetGroupedCompanyMasterSchema = z.object({
  groupedCompanies: z.array(GroupedCompanySchema),
  q: z.string(),
});

export const FollowAdCompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  industryCategoryID: z.number(),
  industry: z.string(),
  foundedYear: z.number(),
  employeeCount: z.number(),
  followerCount: z.number(),
  isFollowed: z.boolean(),
  isWishedIndustry: z.boolean(),
  isWishedJobType: z.boolean(),
  isNew: z.boolean(),
  catchPhrase: z.string(),
  capitalDescription: z.string(),
  pageType: z.string(),
  backgroundImageURLs: z.object({
    '300x126': z.string(),
    '640x268': z.string(),
    '948x392': z.string(),
  }),
  logoURLs: z.object({
    '140x105': z.string(),
    '200x150': z.string(),
    '400x300': z.string(),
  }),
});

export const FollowAdCompanyListSchema = z.object({
  companies: z.array(FollowAdCompanySchema),
});

export const groupedCompaniesSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
  }),
);

export const EngineerProblemListSchema = z.object({
  description: z.string(),
  problems: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
      summary: z.string(),
      difficulty: z.object({
        min: z.number(),
        max: z.number(),
      }),
      problemTags: z.array(z.string()),
      technologyTags: z.array(z.string()),
      thumbnailUrl: z.string(),
    }),
  ),
});

export const EngineerProblemDetailSchema = z.object({
  title: z.string(),
  summary: z.string(),
  thumbnailUrl: z.string(),
  stepRequirements: z.array(
    z.array(
      z.object({
        title: z.string(),
        step: z.number(),
        requirements: z.string(),
        difficulty: z.number(),
        sample: z.string(),
        zip: z.object({
          fileName: z.string(),
          filePath: z.string(),
        }),
        hints: z.array(
          z.object({
            title: z.string(),
            hint: z.string(),
          }),
        ),
        technologyTags: z.array(z.string()),
        problemTags: z.array(z.string()),
        timeRequired: z.number(),
      }),
    ),
  ),
});
